<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="专病小组群聊ID:">
        <span>{{ form.groupId }}</span>
      </el-form-item>
      <el-form-item label="创建时间:">
        <span>{{ form.createdDate }}</span>
      </el-form-item>
      <el-form-item label="报到患者姓名:">
        <span>{{ form.patientUserName }}</span>
      </el-form-item>
      <el-form-item label="手机号码:">
        <span>{{ form.patientMobile }}</span>
      </el-form-item>
      <template>
        <h3 class="leader">
          {{ '组长医生：' + form.leaderDoctorName }}
        </h3>
        <h4>小组成员：</h4>
        <template v-if="form.members && form.members.length > 0">
          <div class="my-group">
            <div v-for="item in form.members" :key="item.imId">
              <div class="doctorInfo">
                <i
                  v-if="!item.leaderFlag && item.type === 1"
                  class="el-icon-error"
                  @click="deleteMyGroup(item)"
                />
                <el-image :src="item.photoUrl" class="avatar" fit="fill" />
                <div>
                  {{ item.name }}
                  <span v-if="item.type === 2">(患者)</span>
                </div>
              </div>
            </div>
            <div class="my-group-add">
              <el-button type="primary" @click="addMyGroup"> 添加 </el-button>
            </div>
          </div>
          <el-dialog title="" width="70%" :visible.sync="isVisible">
            <el-form
              ref="group"
              :inline="true"
              :model="group"
              small="size"
              @submit.native.prevent
            >
              <el-form-item label="医生姓名:">
                <el-input
                  v-model.trim="group.doctorName"
                  maxlength="20"
                  placeholder="请输入医生姓名"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="queryData()">
                  查询
                </el-button>
                <el-button type="primary" @click="queryClear()">
                  重置
                </el-button>
              </el-form-item>
            </el-form>
            <el-table ref="table" v-loading="loading" :data="doctorList">
              <el-table-column type="index" label="序号" width="50" />
              <el-table-column
                prop="teamName"
                label="医生团队"
                align="center"
              />
              <el-table-column
                prop="doctorName"
                label="医生姓名"
                align="center"
              />
              <el-table-column prop="deptName" label="科室" align="center" />
              <el-table-column
                prop="professionalTitleName"
                label="职称"
                align="center"
              />
              <el-table-column prop="mobile" label="联系电话" align="center" />
              <el-table-column
                label="操作"
                show-overflow-tooltip
                width="200"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="addDoctor(scope.row)"
                  >
                    添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页功能模块 -->
            <div class="pagination">
              <el-pagination
                background
                :page-sizes="[10, 20, 50, 100, 200]"
                :current-page="pageInfo.page"
                :page-size="pageInfo.size"
                layout="->,total, sizes, prev, pager, next, jumper"
                :total="pageInfo.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-dialog>
        </template>
        <template v-else>
          <p>无</p>
        </template>
      </template>
      <el-form-item>
        <el-button type="primary" @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AdvisoryGroupDetail',
  data() {
    return {
      isVisible: false,
      groupId: this.$route.query.groupId, // 小组id
      doctorList: [],
      group: {
        doctorName: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      form: {},
    };
  },
  mounted() {
    this.getConsultationDetail();
  },
  methods: {
    // 医患详情
    getConsultationDetail() {
      const param = {
        groupId: this.groupId,
      };
      this.$api.queryGroupDetail(param).then((res) => {
        this.form = res.data;
      });
    },
    // 添加小组成员弹窗
    addMyGroup() {
      this.isVisible = true;
      this.queryClear();
    },
    // 添加小组成员
    addDoctor(group) {
      const { id: doctorId } = group;
      const { groupId } = this;
      const params = {
        doctorId,
        groupId,
      };
      this.$api
        .addDoctorMember(params)
        .then(async (res) => {
          if (res.code === 0) {
            this.getConsultationDetail();
            this.$message({
              type: 'success',
              message: '添加成功!',
            });
            this.isVisible = false;
          }
        })
        .catch(() => {});
    },
    // 删除小组成员
    deleteMyGroup(group) {
      const { id: doctorId, name } = group;
      const { groupId } = this;
      this.$confirm(`删除小组成员${name}，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const param = {
            doctorId,
            groupId,
          };
          this.$api.removeDoctorMember(param).then(async (res) => {
            if (res.code === 0) {
              this.getConsultationDetail();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
          });
        })
        .catch(() => {});
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.queryDoctorList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.queryDoctorList();
    },
    // 重置
    queryClear() {
      this.group = {
        doctorName: '',
      };
      this.pageInfo = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.queryDoctorList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.queryDoctorList();
    },
    // 分页查询问诊类型列表
    queryDoctorList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.group)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .queryFurtherConsultationDoctorList(params)
        .then((res) => {
          this.doctorList = res.data.data;
          this.pageInfo.total = Number(res.data.total);
          this.loading = false;
        })
        .catch(() => {});
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }
}

.pagination {
  overflow: hidden;
}

.my-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .doctorInfo {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    .memberList-name {
      margin-bottom: 10px;
    }
    .el-icon-error {
      position: absolute;
      top: -10px;
      right: 0;
      color: #ff4949;
      font-size: 20px;
      z-index: 9;
    }
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      display: block;
      margin: 10px 10px 10px 0px;
    }
  }

  .my-group-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 120px;
  }
}

.payStatus {
  margin-left: 15px;
}

.leader {
  padding: 15px 0;
}
</style>
